import Vue from 'vue'
import VueRouter from 'vue-router'
import AcceptCustomerPayment from '../views/solutions/AcceptCustomerPayment.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: AcceptCustomerPayment
  },
  {
    path: '/solutions/connect',
    name: 'Connect Payment Service',
    component: () => import('../views/solutions/ConnectPaymentService.vue')
  },
  {
    path: '/solutions/route',
    name: 'Route Payment Intelligently',
    component: () => import('../views/solutions/RoutePaymentIntelligently.vue')
  },
  {
    path: '/solutions/report',
    name: 'Report on Payment',
    component: () => import('../views/solutions/ReportOnPayment.vue')
  },
  {
    path: '/solutions/chargeback/ethoca',
    name: 'Chargeback Warning (Ethoca)',
    component: () => import('../views/solutions/ChargebackWarningEthoca.vue')
  },
  {
    path: '/developers/documentation',
    name: 'Documentation',
    component: () => import('../views/developers/Documentation.vue')
  },
  {
    path: '/cases/merchants',
    name: 'Merchants',
    component: () => import('../views/use_cases/Merchants.vue')
  },
  {
    path: '/cases/aggregators',
    name: 'Merchants Aggregators',
    component: () => import('../views/use_cases/MerchantsAggregators.vue')
  },
  {
    path: '/cases/fintech',
    name: 'Fintech',
    component: () => import('../views/use_cases/Fintech.vue')
  },
  {
    path: '/connections/connections',
    name: 'Connections',
    component: () => import('../views/connections/Connections.vue')
  },
  {
    path: '/connections/partners',
    name: 'Partners',
    component: () => import('../views/connections/Partners.vue')
  },
  {
    path: '/company/contact',
    name: 'Contact us',
    component: () => import('../views/company/Contact.vue')
  },
  {
    path: '/company/about',
    name: 'About',
    component: () => import('../views/company/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach(async (to, from, next) => {
//   // if (!to.matched.length) {
//   //   return next({
//   //     path: '/error/404',
//   //     replace: true,
//   //   });
//   // }
//   // await loadLanguageAsync(store.getters.app.currentLan);
//   next()
// })

export default router
