<template>
<div>
  <div class="moozumi-nav">
    <div class="inner">
      <a href="/"><img class="logo" src="../assets/images/logo.png"/></a>
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        background-color="#FFF"
        text-color="#082540"
        active-text-color="#082540"
        @select="handleSelect">
        <template v-for="(menu, index) in menus">
          <el-submenu :index="`${index}`" :key="index">
            <template slot="title">{{menu.title}}</template>
            <template v-for="(submenu, subindex) in menu.submenus">
              <el-menu-item :key="subindex" :index="`${index}-${subindex}`">
                <div class="moozumi-sub-menu">
                  <img width="20" :src="submenu.icon" class="menu-icon" />
                  <img width="20" :src="submenu.icon_active" class="menu-icon active" />
                  <span>{{submenu.title}}</span>
                </div>
              </el-menu-item>
            </template>
          </el-submenu>
        </template>
      </el-menu>
    </div>
    <!-- <el-button class="moozumi-button" type="primary" round>Get Started</el-button> -->
  </div>
  <div class="moozumi-nav-mobile">
    <div class="moozumi-header">
      <a href="/"><img class="logo" src="../assets/images/logo.png"/></a>
      <el-button class="menu" icon="el-icon-menu" @click="drawer = !drawer"></el-button>
    </div>
    <el-drawer
      title=""
      :visible.sync="drawer"
      direction="rtl"
      size="80%"
      :before-close="handleClose">
      <el-menu
        :default-active="activeIndex"
        :unique-opened="true"
        @select="handleSelectMobile">
        <template v-for="(menu, index) in menus">
          <el-submenu :index="`${index}`" :key="index">
            <template slot="title">{{menu.title}}</template>
            <template v-for="(submenu, subindex) in menu.submenus">
              <el-menu-item :key="subindex" :index="`${index}-${subindex}`">
                <div class="moozumi-sub-menu">
                  <img width="20" :src="submenu.icon" class="menu-icon" />
                  <img width="20" :src="submenu.icon_active" class="menu-icon active" />
                  <span>{{submenu.title}}</span>
                </div>
              </el-menu-item>
            </template>
          </el-submenu>
        </template>
      </el-menu>
    </el-drawer>
  </div>
</div>
</template>
<script>
import Menus from '../config/menus'

export default {
  data () {
    return {
      activeIndex: '0-0',
      menus: Menus,
      drawer: false
    }
  },
  created () {
    const path = window.location.pathname
    let routeInit = false
    if (path !== '/') {
      for (let i = 0; i < this.menus.length; i++) {
        const submenus = this.menus[i].submenus
        for (let j = 0; j < submenus.length; j++) {
          if (path === submenus[j].route) {
            this.activeIndex = `${i}-${j}`
            routeInit = true
            break
          }
        }
        if (routeInit) {
          break
        }
      }
    }
  },
  methods: {
    handleSelect (key) {
      const menu = key.split('-')
      const route = this.menus[menu[0]].submenus[menu[1]].route
      if (this.$route.path !== route) {
        this.$router.push(this.menus[menu[0]].submenus[menu[1]].route)
      }
    },
    handleSelectMobile (key, keyPath) {
      this.drawer = false
      this.handleSelect(key)
    },
    handleClose (done) {
      done()
    }
  }
}
</script>
<style scoped lang="scss">
@import '../styles/variables.scss';

.moozumi-nav {
  box-shadow: 0 1px 4px 0 $nav-box-shadow-color;
  width: 100%;
  overflow: hidden;
  .inner {
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  .logo {
    height: 40px;
    margin-right: 80px;
  }
  .moozumi-button {
    margin-left: 80px;
  }
}
.moozumi-nav-mobile {
  display: none;
  .moozumi-header {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    background:$white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    box-sizing: border-box;
    box-shadow: 0 1px 4px 0 rgba(0,21,41,0.12);
    .logo {
      width: 90px;
    }
    .menu {
      padding: 10px;
    }
  }
}
:deep(.moozumi-nav-mobile .el-menu-item) {
  .moozumi-sub-menu {
    .menu-icon {
      margin-right: 8px;
      &.active {
        display: none;
      }
    }
  }
  &.is-active {
    .moozumi-sub-menu {
      .menu-icon {
        display: none;
        &.active {
          display: inline-block;
        }
      }
    }
  }
}
@media screen and (max-width: 1200px){
  .moozumi-nav {
    display: none;
  }
  .moozumi-nav-mobile {
    display: block;
  }
}
</style>
