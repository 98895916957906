export default [
  {
    title: 'Solutions',
    submenus: [
      {
        title: 'Accept Customer Payment',
        icon: require('../assets/images/nav/accept_customer_payment.png'),
        icon_active: require('../assets/images/nav/accept_customer_payment_active.png'),
        route: '/'
      },
      {
        title: 'Connect Payment service',
        icon: require('../assets/images/nav/connect_payment_service.png'),
        icon_active: require('../assets/images/nav/connect_payment_service_active.png'),
        route: '/solutions/connect'
      },
      {
        title: 'Route Payment Intelligently',
        icon: require('../assets/images/nav/route_payment_intelligently.png'),
        icon_active: require('../assets/images/nav/route_payment_intelligently_active.png'),
        route: '/solutions/route'
      },
      {
        title: 'Report on Payment',
        icon: require('../assets/images/nav/report_on_payment.png'),
        icon_active: require('../assets/images/nav/report_on_payment_active.png'),
        route: '/solutions/report'
      },
      {
        title: 'Chargeback Warning(Ethoca)',
        icon: require('../assets/images/nav/chargeback_ethoca_icon.png'),
        icon_active: require('../assets/images/nav/chargeback_ethoca_active_icon.png'),
        route: '/solutions/chargeback/ethoca'
      }
    ]
  },
  {
    title: 'Developers',
    submenus: [
      {
        title: 'Documentation',
        icon: require('../assets/images/nav/documentation.png'),
        icon_active: require('../assets/images/nav/documentation_active.png'),
        route: '/developers/documentation'
      }
    ]
  },
  {
    title: 'Use cases',
    submenus: [
      {
        title: 'Merchants',
        icon: require('../assets/images/nav/mechants.png'),
        icon_active: require('../assets/images/nav/mechants_active.png'),
        route: '/cases/merchants'
      },
      {
        title: 'Merchant Aggregators',
        icon: require('../assets/images/nav/mechant_aggregators.png'),
        icon_active: require('../assets/images/nav/mechant_aggregators_active.png'),
        route: '/cases/aggregators'
      },
      {
        title: 'Fintech',
        icon: require('../assets/images/nav/fintech.png'),
        icon_active: require('../assets/images/nav/fintech_active.png'),
        route: '/cases/fintech'
      }
    ]
  },
  {
    title: 'Connections',
    submenus: [
      {
        title: 'Connections',
        icon: require('../assets/images/nav/connections.png'),
        icon_active: require('../assets/images/nav/connections_active.png'),
        route: '/connections/connections'
      },
      {
        title: 'Partners',
        icon: require('../assets/images/nav/partners.png'),
        icon_active: require('../assets/images/nav/partners_active.png'),
        route: '/connections/partners'
      }
    ]
  },
  {
    title: 'Company',
    submenus: [
      {
        title: 'Contact us',
        icon: require('../assets/images/nav/contact_us.png'),
        icon_active: require('../assets/images/nav/contact_us_active.png'),
        route: '/company/contact'
      },
      {
        title: 'About',
        icon: require('../assets/images/nav/about.png'),
        icon_active: require('../assets/images/nav/about_active.png'),
        route: '/company/about'
      }
    ]
  }
]
