<template>
  <div class="moozumi-main">
    <div class="banner">
      <div class="banner-inner">
        <div class="left-wrapper">
          <div class="title">Accept Customer Payments</div>
          <div class="content">
            <div>To deliver a great payments experience you must efficiently capture and securely store customers’ payment details. You need the flexibility to launch fast – and to build highly custom payment capturing forms.</div>
            <div class="margin-top-20">moozumi’s flexible payment platform lets you easily capture, store, and transact with ease. You can gather payment details using our “quick start” approach – or connect your checkout experience directly to our PCI-compliant API.</div>
          </div>
          <!-- <div class="moozumi-buttons">
            <el-button type="primary" round>Get Started</el-button>
            <el-button class="margin-left-15 transparent" round>Contact Sales</el-button>
          </div> -->
        </div>
        <img class="checkout" width="494" src="../../assets/images/accept_customer_payment/checkout.png" />
      </div>
    </div>
    <div class="partner">
      <div class="partner-inner">
        <img src="../../assets/images/accept_customer_payment/partner.png" />
        <el-link class="margin-top-30" type="primary">See Our Hundreds of Global Customers <i class="el-icon-right el-icon--right moozumi-hover"></i></el-link>
      </div>
    </div>
    <div class="orchestration">
      <div class="left-wrapper">
        <div class="title">
          <div>PAYMENT ORCHESTRATION IS PREFERRED BY 70% OF BUSINESSES</div>
        </div>
        <div class="margin-top-50 content">Accelerate growth with a flexible payments strategy</div>
        <div class="margin-top-15 description">Transacting with multiple payment services is the new standard. We get you there fast with the most complete Payment Orchestration platform and ecosystem. Reach new markets quickly, increase payments flexibility, and drive more digital revenue with moozumi.</div>
      </div>
      <img src="../../assets/images/accept_customer_payment/orchestration.png" />
    </div>
    <div class="connect">
      <div class="connect-inner">
        <div class="left-wrapper">
          <div class="title">
            <div>HOW moozumi WORKS</div>
          </div>
          <div class="margin-top-50 content">Connect to a World of Payment Services with One API</div>
          <div class="margin-top-15 description">Capture and secure payment methods in a portable PCI-compliant vault. Then leverage our massive ecosystem of moozumi and third-party payment services to enable and optimize digital transactions.</div>
        </div>
        <img src="../../assets/images/accept_customer_payment/connect.png" />
      </div>
    </div>
    <div class="trust">
      <div class="trust-inner">
        <div class="left-wrapper">
          <div class="title">
            <div>moozumi is your Trusted Partner</div>
          </div>
          <div class="margin-top-50 content">Keep Customers Successfully Transacting</div>
          <div class="margin-top-15 description">
            <div>It’s important for you to offer the best payment experience possible. That often means storing your customer’s credit card details for subscriptions or returning shoppers. But how do you keep stored cards evergreen and ready to transact?</div>
            <div class="margin-top-20">moozumi's Account Updater and Network Tokenization solutions provide the answer. These technologies identify and refresh expired or invalid cards. That makes it easy to keep stored payment methods up-to-date so customers are ready to transact and generate revenue for your business.</div>
          </div>
        </div>
        <img src="../../assets/images/accept_customer_payment/trust.png" />
      </div>
    </div>
    <ConnectwiththeexpertsinPaymentOrchestration></ConnectwiththeexpertsinPaymentOrchestration>
  </div>
</template>
<script>
import ConnectwiththeexpertsinPaymentOrchestration from '../../components/ConnectwiththeexpertsinPaymentOrchestration.vue'

export default {
  components: {
    ConnectwiththeexpertsinPaymentOrchestration
  }
}
</script>

<style scoped lang="scss">
@import '../../styles/variables.scss';

.moozumi-main {
  width: 100%;
}
.banner {
  width: 100%;
  background: url(../../assets/images/accept_customer_payment/banner.png);
  background-size: cover;
  padding: 100px 0;
  .banner-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    .left-wrapper {
      width: 652px;
      max-width: 100%;
      .title {
        font-family: Helvetica-Bold;
        font-size: 52px;
        color: #00277E;
        text-align: left;
        line-height: 68px;
      }
      .content {
        text-align: left;
        font-family: HelveticaNeue;
        font-size: 18px;
        color: #061B4B;
        letter-spacing: 0;
        text-align: left;
        line-height: 36px;
        margin-top: 16px;
      }
      .moozumi-buttons {
        display: flex;
        justify-content: flex-start;
        margin-top: 35px;
      }
    }
  }
  .checkout {
    max-width: 100%;
  }
}
.partner {
  padding: 80px 0 65px;
  background: $white;
  .partner-inner {
    max-width: 1200px;
    margin: 0 auto;
  }
  img {
    width: 100%;
  }
}
.orchestration {
  padding: 120px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  .left-wrapper {
    width: 655px;
    max-width: 100%;
    text-align: left;
    .title {
      font-family: HelveticaNeue;
      font-size: 20px;
      color: #03BBFD;
      letter-spacing: 0;
      text-align: left;
      &:before {
        content: '';
        width: 140px;
        height: 6px;
        display: inline-block;
        background-image: linear-gradient(44deg, #02C1FE 0%, #1B55E8 100%);
        border-radius: 5px;
        margin-bottom: 15px;
      }
    }
    .content {
      font-family: HelveticaNeue-Bold;
      font-size: 36px;
      color: $primary-color1;
      letter-spacing: 0;
      text-align: left;
      line-height: 52px;
    }
    .description {
      font-family: HelveticaNeue;
      font-size: 20px;
      color: $primary-color;
      letter-spacing: 0;
      text-align: left;
      line-height: 36px;
    }
  }
  img {
    width: 456px;
    max-width: 100%;
    box-sizing: border-box;
  }
}
.connect {
  padding: 120px 0;
  background: url(../../assets/images/accept_customer_payment/connect_bg.png);
  background-size: cover;
  .connect-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    .left-wrapper {
      width: 655px;
      max-width: 100%;
      text-align: left;
      .title {
        font-family: HelveticaNeue;
        font-size: 20px;
        color: rgba(255,255,255,0.6);
        letter-spacing: 0;
        text-align: left;
        &:before {
          content: '';
          width: 140px;
          height: 6px;
          display: inline-block;
          background-image: linear-gradient(44deg, #02C1FE 0%, #1B55E8 100%);
          border-radius: 5px;
          margin-bottom: 15px;
        }
      }
      .content {
        font-family: HelveticaNeue-Bold;
        font-size: 36px;
        color: $white;
        letter-spacing: 0;
        line-height: 52px;
      }
      .description {
        font-family: HelveticaNeue;
        font-size: 20px;
        color: $white;
        letter-spacing: 0;
        line-height: 36px;
      }
    }
    img {
      width: 490px;
      max-width: 100%;
      box-sizing: border-box;
    }
  }
}
.trust {
  padding: 120px 0;
  .trust-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    .left-wrapper {
      width: 655px;
      max-width: 100%;
      text-align: left;
      .title {
        font-family: HelveticaNeue;
        font-size: 20px;
        color: #03BBFD;
        letter-spacing: 0;
        text-align: left;
        &:before {
          content: '';
          width: 140px;
          height: 6px;
          display: inline-block;
          background-image: linear-gradient(44deg, #02C1FE 0%, #1B55E8 100%);
          border-radius: 5px;
          margin-bottom: 15px;
        }
      }
      .content {
        font-family: HelveticaNeue-Bold;
        font-size: 36px;
        color: #061B4B;
        letter-spacing: 0;
        line-height: 52px;
      }
      .description {
        font-family: HelveticaNeue;
        font-size: 20px;
        color: $primary-color;
        letter-spacing: 0;
        text-align: left;
        line-height: 36px;
      }
    }
    img {
      width: 460px;
      max-width: 100%;
      box-sizing: border-box;
    }
  }
}
@media screen and (max-width: 1200px){
  .banner {
    .left-wrapper {
      padding: 0 15px;
    }
    img {
      margin-top: 20px;
    }
  }
  .orchestration {
    .left-wrapper {
      padding: 0 15px;
    }
    img {
      padding: 0 15px;
      margin-top: 20px;
    }
  }
  .connect {
    .left-wrapper {
      padding: 0 15px;
    }
    img {
      padding: 0 15px;
      margin-top: 20px;
    }
  }
  .trust {
    .left-wrapper {
      padding: 0 15px;
    }
    img {
      padding: 0 15px;
      margin-top: 20px;
    }
  }
}
</style>
