<template>
  <div id="app">
    <el-container>
      <el-header height="80">
        <MoozumiNav></MoozumiNav>
      </el-header>
      <el-main>
        <router-view/>
      </el-main>
      <el-footer>
        <MoozumiFooter></MoozumiFooter>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import MoozumiNav from './components/MoozumiNav.vue'
import MoozumiFooter from './components/MoozumiFooter.vue'
export default {
  components: {
    MoozumiNav,
    MoozumiFooter
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
