<template>
  <div>
    <div class="moozumi-footer">
      <template v-for="(item, index) in menus">
        <div :key="index">
          <div class="moozumi-title">{{item.title}}</div>
          <ul>
            <template v-for="(subitem, subindex) in item.submenus">
              <li :key="subindex">
                <el-link :href="subitem.route" type="primary" :underline="false">{{subitem.title}}</el-link>
              </li>
            </template>
          </ul>
        </div>
      </template>
    </div>
    <el-divider></el-divider>
    <div class="copyright">
      © 2007 - 2022 moozumi, Inc. All rights reserved.
    </div>
  </div>
</template>
<script>
import Menus from '../config/menus'

export default {
  data () {
    return {
      menus: Menus
    }
  }
}
</script>
<style scoped lang="scss">
@import '../styles/variables.scss';

.moozumi-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 15px;
  .moozumi-title {
    font-family: HelveticaNeue-Medium;
    font-size: 25px;
    color: $primary-color;
    line-height: 30px;
    text-align: left;
  }
  ul {
    text-align: left;
    padding: 0;
    li {
      font-family: HelveticaNeue;
      font-size: 15px;
      color: $primary-color;
      line-height: 35px;
    }
  }
}
.copyright {
  padding: 20px 15px;
  opacity: 0.5;
  font-family: HelveticaNeue;
  font-size: 18px;
  color: $primary-color;
  letter-spacing: 0;
  line-height: 35px;
}
</style>
